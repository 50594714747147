export function removeDoubleSlashFromUrl(url) {
	if (url == null) {
		return "";
	}
	return url.replace(/([^:]\/)\/+/g, "$1");
}

export function setScrollToElement(id_element) {
	if (typeof id_element !== "string") {
		throw new TypeError("Element ID must be a string");
	}
	const element = document.querySelector(id_element);
	if (!element) {
		return;
	}
	const y = element.getBoundingClientRect().top + window.scrollY;
	window.scroll({ top: y, behavior: "smooth" });
}

export function getUrlVars() {
	const vars = {};
	const parts = window.location.href.replace(
		/[?&]+([^=&]+)=([^&]*)/gi,
		function (m, key, value) {
			vars[key] = value;
		},
	);
	return vars;
}
