import "../../sass/front.scss";
import Vue from "vue";
import VueRouter from "vue-router";
import config from "./config";

// i18next for translations
import i18n from "./i18n";

// ImageKit
import ImageKit from "imagekitio-vue";
Vue.use(ImageKit, {
	urlEndpoint: "https://ik.imagekit.io/ctyttbqpls/",
	// publicKey: "your_public_api_key",
	// authenticationEndpoint: "https://www.your-server.com/auth"
});

// Axios
import axios from "axios";
axios.defaults.baseURL = config.publicUrl;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios = axios;

import "./fontawesome.js";

// Set Global Vars
Vue.prototype.$publicUrl = config.publicUrl;
Vue.prototype.$appName = config.appName;
Vue.prototype.$appBaseUrl = config.appBaseUrl;
Vue.prototype.$appAdminBaseUrl = config.appAdminBaseUrl;
Vue.prototype.$token = config.token;
Vue.prototype.$hCaptchaSiteKey = config.hcaptcha.sitekey;

const routes = [
	{ path: "/", name: "Login", component: import("./components/login.vue") },
];
// Vue Config
Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	routes,
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("login", () => import("./components/login.vue"));
Vue.component("register", () => import("./components/register.vue"));
Vue.component("recover", () => import("./components/recover.vue"));
Vue.component("adminlogin", () => import("./components/adminlogin.vue"));
Vue.component("adminrecover", () => import("./components/adminrecover.vue"));

// Scroll to element
import { setScrollToElement } from "./helpers";

new Vue({
	router,
	i18n,
	el: "#app",
	data: {
		loginOn: true,
		registerOn: false,
		recoverOn: false,
		open: false,
		adminRecoverOn: false,
		adminLoginOn: true,
	},
	mounted() {
		if (window.screen.width > 600) {
			this.open = true;
		}
		document.addEventListener("DOMContentLoaded", () => {
			const dropdown = document.querySelector(".dropdown");
			dropdown?.addEventListener("click", (event) => {
				event.stopPropagation();
				dropdown.classList.toggle("is-active");
			});

			const dropdownItems = document.querySelectorAll(".dropdown-item");
			dropdownItems.forEach((item) => {
				item.addEventListener("click", (event) => {
					event.stopPropagation();
					localStorage.setItem("language", event.target.dataset.value);
				});
			});
		});
	},
	methods: {
		goRegister() {
			this.registerOn = true;
			this.loginOn = false;
			this.recoverOn = false;
			this.open = true;
			setTimeout(() => {
				this.setScrollToElement("#register_zone");
			}, 100);
		},
		goLogin() {
			this.loginOn = true;
			this.registerOn = false;
			this.recoverOn = false;
			this.open = true;
			setTimeout(() => {
				this.$nextTick(() => {
					this.setScrollToElement("#login_zone");
				});
			}, 50);
		},
		goRecover() {
			this.loginOn = false;
			this.registerOn = false;
			this.recoverOn = true;
			this.open = true;
			setTimeout(() => {
				this.$nextTick(() => {
					this.setScrollToElement("#recover_zone");
				});
			}, 50);
		},
		goAdminLogin() {
			this.adminRecoverOn = false;
			this.adminLoginOn = true;
		},
		goAdminRecover() {
			this.adminRecoverOn = true;
			this.adminLoginOn = false;
		},
		setScrollToElement: setScrollToElement,
	},
});
